<template>
  <div>
    <v-dialog v-model="open" persistent max-width="400px">
      <v-card>
        EditOption.vue
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>
        <v-card-title>
          <span class="page-heading">Edit Option</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mx-3 mb-0" no-gutters>
            <v-text-field
              name="option"
              label="Option"
              v-model="editingItem.value"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">cancel</v-btn>
          <v-btn
            color="primary"
            @click="saveOption()"
            elevation="0"
            :loading="processing"
            >submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
    },
    editingOption: {
      type: Object,
    },
  },
  created() {
    this.editingItem = Object.assign({}, this.editingOption);
  },
  data: () => {
    return {
      processing: false,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("onCancelEditOption");
    },
    async saveOption() {
      this.$emit("onSaveEditOption", this.editingItem);
    },
  },
};
</script>
<style scoped>
.bold-question {
  font-weight: 700 !important;
}
</style>
